<template>
  <div>
    <el-form ref="form" :model="form" inline>
      <!-- 平台 -->
      <el-form-item :label="$t('title.platform')">
        <el-select v-model="form.platform" :placeholder="$t('page.selectPlaceholder')" clearable filterable>
          <el-option
            v-for="item in platformList"
            :key="item.id"
            :label="item.platformCode"
            :value="item.platformCode"
          />
        </el-select>
      </el-form-item>
      <!-- 消息id -->
      <el-form-item :label="$t('title.Name')">
        <el-input v-model="form.userName" :placeholder="$t('page.inputPlaceholder')" />
      </el-form-item>
      <el-form-item label="BU">
        <el-select v-model="form.bu" clearable filterable:placeholder="$t('page.selectPlaceholder')">
          <el-option
            v-for="item in buOption"
            :key="item.id"
            :label="item.buName"
            :value="item.buName"
          />
        </el-select>
      </el-form-item>
      <!-- 评论时间 -->
      <el-form-item :label="$t('title.selectionperiod')">
        <el-date-picker
          v-model="form.date"
          :end-placeholder="$t('title.endtime')"
          :start-placeholder="$t('title.starttime')"
          :unlink-panels="true"
          range-separator="~"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']"
        />
      </el-form-item>
      <div style="margin-bottom:18px">
        <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
        <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
        <el-button @click="handleExport">{{ $t('page.export') }}</el-button>
      </div>
    </el-form>
    <el-table
      ref="multipleTable"
      v-loading="TableLoading"
      :data="tableDatas"
      :header-cell-style="{background:'#fafafa',color:'#606266'}"
      class="mb-3"
      max-height="500px"
      @row-click="overallClick"
    >
      <el-table-column
        :label="$t('page.No')"
        align="center"
        sortable
        type="index"
        width="80"
      >
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('title.Name')"
        align="center"
        prop="userName"
        sortable
        width="105"
      />

      <el-table-column
        align="center"
        label="BU组"
        prop="bu"
        sortable
        width="105"
      />
      <el-table-column
        :label="$t('title.Closedtickets')"
        align="center"
        prop="finishedWork"
        sortable
        width="125"
      />
      <el-table-column
        :label="$t('title.Sentmessages')"
        align="center"
        prop="replyNumber"
        sortable
        width="125"
      />
      <el-table-column
        :label="$t('title.Markedasread')"
        align="center"
        prop="noReplyNumber"
        sortable
        width="125"
      />
      <el-table-column
        :label="$t('title.Averageresponsetime')"
        align="center"
        prop="avgReplyTime"
        sortable
      />
      <el-table-column
        :label="$t('title.Longestrespondingtime')"
        align="center"
        prop="maxReplyTime"
        sortable
      />

      <el-table-column
        :label="$t('title.Shortestrespondingtime')"
        align="center"
        prop="minReplyTime"
        sortable
      />
      <el-table-column
        :label="$t('title.Averagerespondingtime')"
        align="center"
        prop="avgReactTime"
        sortable
      />
      <el-table-column
        :label="$t('title.Transferringtime')"
        align="center"
        prop="workFromNumber"
        sortable
        width="115"
      />
      <el-table-column
        :label="$t('title.Transferredtime')"
        align="center"
        prop="workToNumber"
        sortable
        width="125"
      />
      <el-table-column
        :label="$t('title.BadReviewWorkOrder')"
        align="center"
        prop="badReviewNumber"
        sortable
        width="100"
      />
      <el-table-column
        :label="$t('title.PerfectWorkOrder')"
        align="center"
        prop="perfectNumber"
        sortable
        width="100"
      />
      <el-table-column
        :label="$t('title.PerfectWorkOrderRate')"
        align="center"
        prop="perfectNumberRate"
        sortable
        width="100"
      />
    </el-table>
    <el-pagination
      :current-page="pager.current"
      :page-size="pager.size"
      :page-sizes="[10, 50, 100, 200, 300]"
      :total="pager.total"
      layout="total, sizes, prev, pager, next, jumper"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
// import oms_base from '@/api/a-base-host'
import mixin from '@/mixin/oms-mixin'
import { queryTerraceList } from '@/api/omsorder'
import { allBaseBu, pageMessageCount } from '@/api/service-management'

import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'

dayjs.locale('zh-cn')

dayjs.extend(quarterOfYear)

export default {
  mixins: [mixin],
  data() {
    return {
      pager: {
        current: 1,
        size: 10,
        total: 0
      },
      TableLoading: false,
      tableDatas: [],
      form: {
        userId: '',
        bu: '',
        userName: '',
        date: [dayjs().startOf('quarter').format('YYYY-MM-DD HH:mm:ss'), dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss')],
        platform: ''
      },
      buOption: [],
      platformList: [] // 平台条件查询
    }
  },

  computed: {
    queryParameter() {
      const [startTime = '', endTime = ''] = this.form.date || []
      return Object.assign({}, this.form, this.pager, { startTime, endTime })
    },
    exportUrl() {
      // const [startTime, endTime] = this.form.date || []
      // const { bu, userName } = this.form
      // return oms_base + `/oms/returns/exportMessageCountInfos?bu=${bu}&userName=${userName}&startTime=${startTime}&endTime=${endTime}`
      const obj = {}
      const { bu, userName, platform } = this.form
      const [startTime, endTime] = this.form.date || []
      const OBJ = Object.assign({}, { bu, userName, platform }, { startTime, endTime })
      for (var key in OBJ) {
        OBJ[key] ? obj[key] = OBJ[key] : ''
      }
      let url = ''
      for (var i in obj) {
        url = `${i}=${obj[i]}` + '&' + url
      }
      url = url.substr(0, url.length - 1)
      return process.env.VUE_APP_ERP_NEW_API + 'mos-web-core/Customer/exportMessageCountInfos?' + url
    }
  },

  mounted() {
    this._pageMessageCount()
    this._allBaseBu()
    this._queryTerraceList()
  },
  methods: {
    // 获取平台消息
    async _queryTerraceList() {
      const { datas } = await queryTerraceList()
      this.platformList = datas
      this.platformList = this.platformList.filter((item) => {
        return item.platformCode === 'amazon' ||
        item.platformCode === 'ebay' ||
        item.platformCode === 'walmart' ||
        item.platformCode === 'Shopify' ||
        item.platformCode === 'standwebsite' ||
        // sb正式测试不一样
        item.platformCode === 'Tik Tok' ||
        item.platformCode === 'TikTok'
      })
    },
    // 导出文件
    handleExport() {
      window.open(this.exportUrl, '_blank')
    },
    // 查询所有BU
    async _allBaseBu() {
      const { datas } = await allBaseBu()
      this.buOption = datas
    },
    // 点击查询获取信息
    queryClick() {
      this.pager.current = 1
      this._pageMessageCount(this.queryParameter)
    },
    // 点击重置清空文本框信息
    resetClick() {
      this.form = this.$options.data().form
      this._pageMessageCount(1)
    },
    // 默认查询
    async _pageMessageCount(params) {
      try {
        this.TableLoading = true
        const { datas } = await pageMessageCount(this.queryParameter)
        this.tableDatas = datas.records
        this.pager = datas.pager
      } finally {
        this.TableLoading = false
      }
    },
    handleSizeChange(val) {
      this.pager.size = val
      this._pageMessageCount(this.queryParameter)
    },
    handleCurrentChange(val) {
      this.pager.current = val
      this._pageMessageCount(this.queryParameter)
    },
    // 点击当前行传入要跳转模块的 id
    overallClick(row) {
      this.$router.push({
        path: 'statistics-details',
        append: 'true',
        query: { ...this.userInfo, 'userId': row.userId, 'userName': row.userName }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.mark,
.count {
  color: red;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

/deep/.el-tabs__header {
  margin: 0 !important;
}
.Row-Input {
  height: 38px;
  overflow: hidden;
}
.show-row {
  height: auto;
}
</style>
